
import { Vue, Component } from 'vue-property-decorator'
import Swiper from 'swiper'
import contact from '@/components/contact.vue'
import api from '@/api/'

@Component({
  components: {
    contact
  }
})
export default class Partner extends Vue {
  private article: any = {}
  private mounted () {
    /* const mySwiper = new Swiper('.swiper-container', {
      autoplay: false,
      direction: 'vertical',
      mousewheel: true,
      pagination: {
        el: '.swiper-pagination'
      }
    }) */
  //  api.articleGet(1).then((res) => this.article = res.result) 
  }
}
